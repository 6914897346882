import React from "react";
import { GoogleMap } from "@react-google-maps/api";

const googleMapId = process.env.REACT_APP_MAP_ID   // This we have to get it from https://developers.google.com/maps/documentation/get-map-id

function addZoneLayer(map, placeIds) {
  const featureLayer = map.getFeatureLayer("POSTAL_CODE");

  featureLayer.style = ({ feature }) => {
    const placeId = feature.placeId;
    if (!placeIds.includes(placeId)) {
      return;
    }

    return {
      fillColor: '#fb6b27',
      fillOpacity: 0.5,
      strokeColor: "#3F3F6E"
    };
  };
}

const MapComponent = ({ zipcodeInfo }) => {
  const {
    latitude,
    longitude,
    arrayOfNeighbourGooglePlaceId = []
  } = zipcodeInfo;



  const currentLocation = { lat: latitude, lng: longitude };

  const onLoad = (map) => {
    addZoneLayer(map, arrayOfNeighbourGooglePlaceId);
    const bounds = new window.google.maps.LatLngBounds(currentLocation);
    // map.setCenter(bounds);
  };

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      center={currentLocation}
      key={currentLocation.lat + "-" + currentLocation.lng}
      zoom={12}
      onLoad={onLoad}
      options={{ mapId: googleMapId }}
    //onUnmount={onUnmount}
    >
      {/* <Circle
        center={currentLocation}
        options={options}
        key={currentLocation.lat + "-" + currentLocation.lng}
      /> */}
      {/* Child components, such as markers, info windows, etc. */}
      {/* {arrayOfNeighbourGooglePlaceId.length === 1 && (
      
      )} */}
    </GoogleMap>
  );
};

export default MapComponent;
