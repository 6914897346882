import moment from "moment";
const responseInterceptorSuccess = (successResponseParam) => {
    const successResponse = successResponseParam;


    const currentTime = moment().unix();
    const startTime = successResponse.config.headers["request-startTime"];
    successResponse.headers["request-duration"] = currentTime - startTime;

    return {
        hasError: false,
        data: { ...successResponse.data, fetchApiTime: currentTime },
    };
};

export default responseInterceptorSuccess;
