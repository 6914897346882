import React, { useEffect, useState, useRef } from "react";
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import "./OtpVerify.scss";
var reg = new RegExp("^[0-9]*$");

function OTPComponent({ digits, values, onChange = null, onKeyDown = null }) {
    let refsArr = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    refsArr = refsArr.splice(0, digits);

    const onChangeHandler = (e, i) => {

    };

    const onKeyDownHandler = (e, i) => {
        const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        if (numbers.indexOf(e.key) >= 0) {
            onChange(e.key, i);
            if (refsArr[i + 1] && refsArr[i + 1].current !== null) {
                refsArr[i + 1].current.focus();
            }
        } else if (e.key === "Backspace") {
            if (values[i].length >= 0) {
                onChange("", i);
                if (refsArr[i - 1] && refsArr[i - 1].current !== null) {
                    refsArr[i - 1].current.focus();
                }
            }
        }
    };
    // console.log("refs", values, refsArr);
    return (
        <div className="otp-verify-container">
            {values.map((value, i) => {
                return (
                    <input
                        key={i}
                        ref={refsArr[i]}
                        id="otp-input-box"
                        type="number"
                        onChange={(e) => onChangeHandler(e, i)}
                        onKeyDown={(e) => onKeyDownHandler(e, i)}
                        maxLength="1"
                        value={value}
                    />
                );
            })}
        </div>
    );
}

function OtpVerify({ digits = 5, otp, setOTP, setDisabled = true, }) {
    const [values, setValues] = useState([]);
    useEffect(() => {
        let inputVals = [];
        for (let x = 0; x < digits; x++) {
            inputVals.push("");
        }
        setValues(inputVals);

    }, []);


    const onChange = (value, i) => {
        const inputValues = [...values];
        inputValues[i] = value;
        setValues(inputValues);
        setOTP(inputValues.reduce((a, b) => a + b));

        if (otp.length >= 4) {
            setDisabled(false)
        }

    };


    return <OTPComponent digits={digits} values={values} onChange={onChange} />;
}

export default OtpVerify;
