import React, { useState, useContext, useEffect } from 'react'
import InputBox from '../../atomic/inputbox/InputBox'
import { isValidEmail, isValidAptNo, isMobileNo, } from "../../utils/regexValidation"
import { FutureCustomerContext } from '../../context-api/data-provider/FutureCustomerDataProvider'
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import AtomicButton from '../../atomic/button/Button'
import { Alert, AlertTitle } from '@mui/material'
import { verbiage } from './../../utils/constants';
import { API_NETWORK_ERROR } from './../../utils/constants';
import { Error404 } from '../error404/Error404';

const FutureCustomer = (props) => {

    const { zipcode = null, bizType = null, cuisineType = null } = props
    const { FUTURE_CUST_MSG, FUTURE_CUST_SUBTITLE_MSG } = verbiage

    const { futureCustomerAPIResponse,
        setFutureCustomerAPIResponse,
        onFutureCustomer, } = useContext(FutureCustomerContext);
    const [validationErrors, setValidationErrors] = useState(null)
    const { data, loading, message, success } = futureCustomerAPIResponse
    const [formValues, setFormValues] = useState({})
    const { email, phoneNo, referralNo } = formValues
    const [openThankYouComp, SetOpenThankYouComp] = useState(false)


    useEffect(() => {

        SetOpenThankYouComp(false)
        if (success === true && message === "Future customer added successfully.") {
            SetOpenThankYouComp(true)
        }

    }, [data])



    const onChangeHandler = (key) => (value, timepickerContext) => {


        value.preventDefault();
        const val = value.target.value;

        if (key === "zipcode") {
            if (val.length <= 5) setFormValues({ ...formValues, [key]: val });
            return
        }
        setFormValues({ ...formValues, [key]: val });

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let Errors = {}

        if (email === undefined || !isValidEmail(email)) {
            Errors.email = 'Invaild email format'
        }
        if (phoneNo === undefined || !isMobileNo(phoneNo)) {
            Errors.phoneNo = 'Invaild Phone No format'
        }

        if (referralNo === undefined || isValidAptNo(referralNo)) {
            Errors.referralNo = 'Invalid Referral No format'
        }


        if (Object.keys(Errors).length > 0) {
            setValidationErrors(Errors);
        } else {
            setValidationErrors(null);

            const payloadFutureTest = {
                email: email,
                manual_notes: "returant",
                zipcode: zipcode,
                cuisine_category: cuisineType,
                account_type: bizType,
                referral_no: referralNo,
                phone_no: phoneNo,

            }

            await onFutureCustomer(payloadFutureTest)

        }

    };


    if (success === false && message === API_NETWORK_ERROR) {

        return (<Error404 />)

    }

    if (openThankYouComp) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid white',
                backgroundColor: 'white',
                width: isIpad ? '425px' : '',
                height: isIpad ? '100%' : 'auto',
                borderRadius: '10px',
                margin: isIpad ? ' 15% 20%' : '05% 10% 10% 20%',
                boxShadow: '0 3px 6px 0 #000000',
                padding: isIpad ? '30px' : '40px'
            }}>
                <div style={{ marginBottom: '10px', color: '#3F3F6E', fontSize: '30px', fontWeight: '700' }}> Thank you </div>
                <div style={{ color: '#3F3F6E', fontSize: '16px', fontWeight: '400' }}>{FUTURE_CUST_SUBTITLE_MSG}</div>
            </div>
        )
    }

    if (!openThankYouComp) {

        return (

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid white',
                backgroundColor: 'white',
                width: isIpad ? '425px' : '',
                height: isIpad ? '100%' : 'auto',
                borderRadius: '10px',
                margin: isIpad ? ' 15% 20%' : '05% 10% 10% 20%',
                boxShadow: '0 3px 6px 0 #000000',
                padding: isIpad ? '30px' : '40px'
            }}>
                <div style={{ marginBottom: '10px', color: '#3F3F6E', fontSize: '30px', fontWeight: '700' }}> Enter Email</div>
                {validationErrors !== null && (
                    <Alert
                        severity="error"
                        sx={{ width: "90.5%", border: "solid 1px red", backgroundColor: '#FDEDEB', color: 'red' }}
                    >
                        <AlertTitle>Error</AlertTitle>

                        <ul>
                            {Object.keys(validationErrors).map((el, index) => (
                                <li key={index}>
                                    {" "}
                                    <strong>{validationErrors[el]}</strong>
                                </li>
                            ))}
                        </ul>
                    </Alert>)}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px', width: "89%",
                    border: "solid 1px #FF7A28",
                    marginBottom: "5px", backgroundColor: '#fff3ea',
                    marginTop: '10px',
                    height: 'auto', borderRadius: '3px'
                }}>
                    <div style={{ color: "#FF7A28", fontWeight: 600, fontSize: '16px' }}>{FUTURE_CUST_MSG}</div>
                    <InputBox
                        type="text"
                        label="Email*"
                        size="small"
                        sx={{
                            width: "98%",
                            height: "auto",
                            margin: "20px 5px 5px 0px",
                            backgroundColor: 'white'
                        }}
                        name="email"
                        value={email}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["email"] && validationErrors["email"].length > 0}
                        onChange={onChangeHandler("email")}
                    />
                    <InputBox
                        type="text"
                        label="Phone No"
                        size="small"
                        sx={{
                            width: "98%",
                            height: "auto",
                            margin: "5px 5px 5px 0px",
                            backgroundColor: 'white'
                        }}
                        name="email"
                        value={phoneNo}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["phoneNo"] && validationErrors["phoneNo"].length > 0}
                        onChange={onChangeHandler("phoneNo")}
                    />
                    <InputBox
                        type="text"
                        label="Referral no"
                        size="small"
                        sx={{
                            width: "98%",
                            height: "auto",
                            margin: "5px 5px 5px 0px",
                            backgroundColor: 'white'
                        }}
                        name="referralNo*"
                        value={referralNo}
                        maxLength="20"
                        error={validationErrors !== null && validationErrors["referralNo"] && validationErrors["referralNo"].length > 0}
                        onChange={onChangeHandler("referralNo")}
                    />

                    <AtomicButton
                        label="Submit"
                        handleSubmit={handleSubmit}
                        style={{ backgroundColor: '#3F3F6E' }}
                        loading={loading}
                        disabled={false}
                    />


                </div>
            </div>
        )
    }
}

export default FutureCustomer