import React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import "./TimePickerInput.scss";

// Documentation link - https://mui.com/x/api/date-pickers/time-field/

const TimePickerInput = (props) => {

    const { onChange = null, name = 'demo', label = 'demo', disabled = false, disableFuture = false,
        size = 'small', sx = {}, value, variant = 'outlined', id = '' } = props

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimeField']}>
                <TimeField
                    onChange={onChange}
                    name={name}
                    label={label}
                    disabled={disabled}
                    disableFuture={disableFuture}
                    size={size}
                    sx={sx}
                    value={value}
                    variant={variant}
                    id={id}
                    className='custom-css-outlined-input-timepicker'
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default TimePickerInput




