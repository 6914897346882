// login apis
export const SIGNUP_API = "/SignupKitchen";
export const LOGIN_API = '/LoginKitchen';
export const VERIFY_OTP_API = "/OtpKitchenEmailVerify";
export const FORGOT_PASSWORD_API = "/KitchenForgotPassword";
export const RESET_PASSWORD_API = "/KitchenResetPassword";
export const UPDATE_KITCHEN_OPERATION = "/UpdateKitchenOperation";
export const FUTURE_CUSTOMER_API = "/CreateFutureCustomer";
export const GET_SERVING_ZIPCODE_ADMIN_API = "/GetServingZipcodeForAdmin"
export const ONLOAD_INFORMATION_API = "/OnLoadInformation";
