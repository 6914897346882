import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import InputBox from '../../atomic/inputbox/InputBox'
import SelectBox from '../../atomic/selectbox/SelectBox';
import { SignUpDataContext } from '../../context-api/data-provider/SignupDataProvider';
import { GlobalStateDataContext } from '../../context-api/data-provider/GlobalStateDataProvider'
import AtomicButton from '../../atomic/button/Button';
import { isValidZipcodeFormat } from "../../utils/regexValidation"
import { Alert, AlertTitle } from '@mui/material';
import responseErrorMapping from '../../utils/error.response.mapping';
import FutureCustomer from './../futurecustomer/FutureCustomer';
import CongratsStep from './../congratsstep/CongratsStep'
import { API_NETWORK_ERROR } from './../../utils/constants'
import { Error404 } from '../error404/Error404';
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'


const CheckZipcode = () => {
    const navigate = useNavigate();
    const { signupAPIResponse,
        setSignupAPIResponse,
        onSignUp,
        onServingZipcode,
        setServingZipcodeAPIResponse,
        servingZipcodeAPIResponse } = useContext(SignUpDataContext);

    const { zipcodeInfo, setZipcodeInfo, OnLoadInformation,
        onLoadInfoResponse,
        setOnLoadInfoResponse, showCongrat, setShowCongrat } = useContext(GlobalStateDataContext)

    const [formValues, setFormValues] = useState({ bizType: 'Restaurant', cuisineType: 'Indian' })
    const bizOptions = ['Cloud kitchen', 'Restaurant', 'Home chef', 'Catering'];
    const cuisineOptions = ['Indian', 'Mexican', 'American chinese', 'Italian', 'Lebanese', 'Turkish', 'Fast food', 'Chinese', 'Mix of all']
    const { bizType, cuisineType, zipcode } = formValues
    const [validationErrors, setValidationErrors] = useState(null)
    const { data, loading, message, success } = servingZipcodeAPIResponse
    const [notServingLocation, setNotServingLocation] = useState(false)
    const RedirectToSignup = () => navigate("/login");

    useEffect(() => {
        setNotServingLocation(false)
        setShowCongrat(false)
        if (success === false && (message === `No Record Found in Database by that ${zipcode}` || message === "We don't serve this zipcode")) {
            setNotServingLocation(true)
        }

        if (success === true && message === 'getting data from database') {
            setZipcodeInfo({
                latitude: data?.data?.geolocation?.latitude,
                longitude: data?.data?.geolocation?.longitude,
                managing_branch_name: data?.data?.managing_branch_name,
                googlePlaceId: data?.data?.geolocation?.googlePlaceId,
                arrayOfNeighbourGooglePlaceId: data?.data?.arrayOfNeighbourGooglePlaceId,
                arrayOfNeighbourZipcodes: data?.data?.arrayOfNeighbourZipcodes,
            })
            setShowCongrat(true)
        }

    }, [data])

    const onChangeHandler = (key) => (value, timepickerContext) => {

        // For seectbox box we get value from (value)
        if (key === 'bizType') {
            setFormValues({ ...formValues, [key]: value });
            return
        }

        if (key === 'cuisineType') {

            setFormValues({ ...formValues, [key]: value });
            return
        }



        // For free text input box we get value from (target.value)
        value.preventDefault();
        const val = value.target.value;

        if (key === "zipcode") {
            if (val.length <= 5) setFormValues({ ...formValues, [key]: val });
            return
        }
        setFormValues({ ...formValues, [key]: val });

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let Errors = {}

        if (!isValidZipcodeFormat(zipcode) || zipcode === undefined) {
            Errors.zipcode = "Invalid Zipcode format"
        }


        if (Object.keys(Errors).length > 0) {
            setValidationErrors(Errors);
        } else {
            setValidationErrors(null);


            await onServingZipcode(zipcode)
            // await onFutureCustomer(payloadFutureTest)

        }

    };


    if (success === false && message === API_NETWORK_ERROR) {

        return (<Error404 />)

    }

    if (notServingLocation) {
        return (<FutureCustomer zipcode={zipcode} bizType={bizType} cuisineType={cuisineType} message={message} />)
    }

    if (showCongrat) {
        return (<CongratsStep />)
    }


    if (!notServingLocation) {

        return (

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid white',
                backgroundColor: 'white',
                width: isIpad ? '425px' : '',
                height: isIpad ? '100%' : 'auto',
                borderRadius: '10px',
                margin: isIpad ? ' 15% 20%' : '05% 10% 10% 20%',
                boxShadow: '0 3px 6px 0 #000000',
                padding: isIpad ? '30px' : '40px'
            }}>
                <div style={{ marginBottom: isIpad ? '10px' : '20px', color: '#3F3F6E', fontSize: isIpad ? '20px' : '30px', fontWeight: '700' }}> Enter Zipcode</div>
                {validationErrors !== null && (
                    <Alert
                        severity={isIpad ? '' : 'error'}
                        sx={{ width: isIpad ? "90%" : "91%", border: "solid 1px red", backgroundColor: '#FDEDEB', color: 'red' }}
                    >

                        {isIpad ? <div style={{ fontSize: '12px', fontWeight: 600 }}>Error</div> : <AlertTitle sx={{ fontSize: '14px', fontWeight: 600 }}>Error</AlertTitle>}

                        {Object.keys(validationErrors).map((el, index) => (
                            <li style={{ fontSize: '12px' }} key={index}>
                                {" "}
                                <strong>{validationErrors[el]}</strong>
                            </li>
                        ))}

                    </Alert>)}

                <InputBox
                    type="text"
                    label="Zipcode*"
                    size="small"
                    sx={{
                        width: "98%",
                        height: "auto",
                        margin: "10px 5px 5px 0px",
                    }}
                    name="zipcode"
                    value={zipcode}
                    maxLength="10"
                    // error={validationErrors !== null && validationErrors["email"] && validationErrors["email"].length > 0}
                    onChange={onChangeHandler("zipcode")}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <SelectBox
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        label="Biz Type"
                        value={bizType}
                        onChange={onChangeHandler("bizType")}
                        options={bizOptions}
                    />

                    <SelectBox
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        label="Cuisine Type"
                        value={cuisineType}
                        onChange={onChangeHandler("cuisineType")}
                        options={cuisineOptions}
                    />
                </div>

                <AtomicButton
                    label="Signup"
                    handleSubmit={handleSubmit}
                    style={{ width: '98.5%', backgroundColor: '#3F3F6E' }}
                    loading={loading}
                    disabled={false}
                />

                <div style={{ color: '#3F3F6E', fontSize: '14px', fontWeight: 600 }}>  Already have an account? &nbsp;
                    <span style={{ color: '#0834e8', fontSize: '14px', fontWeight: 600, cursor: 'pointer' }} onClick={RedirectToSignup}>
                        Login
                    </span>
                </div>


            </div>
        )
    }
}

export default CheckZipcode