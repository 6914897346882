// backend validation error messages mapping ,
// add backend error messages to the ist ENABLE_ANALYZER



const zipcodeBusinessStats = (managing_branch_name) => {

    const zipcodeBizStats = {

        // REPLACE WITH REAL TIME BRANCH NAME BEFORE GOING PRODUCTION 
        '5325dummybranchname': {
            zipcodes: [60025, 60029, 60043, 60053, 60076, 60077, 60091, 60093, 60201, 60202, 60203, 60625, 60626, 60630, 60645,
                60646, 60659, 60660, 60712, 60714], population: '200,00.00', expected: ' 100 Million perday'
        }

    }

    return zipcodeBizStats[managing_branch_name] || { zipcodes: [], population: 'No stats Found', expected: 'No stats Found' }

}

export default zipcodeBusinessStats
