import React from 'react'
import { useNavigate } from "react-router-dom";
import './NoMatch.scss'
import AtomicButton from '../../atomic/button/Button';
import variables from "../../utils/assets/_variables.scss";

const NoMatch = ({ textmessage }) => {
    const navigate = useNavigate();

    const onRedirectHandler = () => {
        navigate("/");
    };
    return (
        <div className='no-match-container'>
            <h2> {textmessage}</h2>
            <div>
                <AtomicButton
                    loading={false}
                    handleSubmit={onRedirectHandler}
                    label="Redirect to home.."
                    // endIcon
                    // startIcon
                    loadingIcon
                    loadingButtonStyle={{}}
                    style={{
                        backgroundColor: variables.backgroundButtonBlue,
                        width: "100%",
                        height: "35px",
                        marginTop: "15px",
                        marginLeft: "25px"
                    }}
                />

            </div>

        </div>
    )
}

export default NoMatch