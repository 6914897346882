import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { string, bool, func, shape } from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./InputBox.scss";

export default function InputBox(props) {
    const {
        label,
        error,
        size,
        width,
        height,
        name,
        inputErrorMsg,
        onChange,
        inputProps,
        type,
        value,
        autoFocus,
        sx,
        onBlur = null,
        showLoader = false,
        ...rest
    } = props;

    let InputType = type;
    let InputProps = inputProps;

    const [showPassword, toggleShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        toggleShowPassword(!showPassword);
    };

    //for showLoader
    let endAdornment = showLoader
        ? {
            endAdornment: (
                <InputAdornment position="start" sx={{ color: "orange" }}>
                    <CircularProgress color="inherit" size={15} />
                </InputAdornment>
            ),
        }
        : {};

    //for input type PASSWORD adding EYE Icon which user can toggle to show and hide the password
    if (type.toLowerCase() === "password") {
        endAdornment = {
            endAdornment: (
                <InputAdornment position="end" onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
            ),
        };
        InputType = showPassword ? "text" : type;
    }

    InputProps = { ...inputProps, ...endAdornment }

    return (
        <TextField
            autoFocus={autoFocus}
            error={error}
            label={label}
            size={size}
            className="custom-css-outlined-input"
            // id="custom-css-outlined-input"
            sx={{ width, height, ...sx }}
            onChange={onChange}
            name={name}
            helperText={inputErrorMsg}
            // inputProps={inputProps}
            type={InputType}
            // maxLength={5}
            value={value}
            onBlur={onBlur}
            InputProps={InputProps}
            {...rest}
        />
    );
}

InputBox.propTypes = {
    label: string,
    size: string,
    error: bool,
    width: string,
    height: string,
    onChange: func.isRequired,
    name: string,
    inputErrorMsg: string,
    inputProps: shape({}),
    type: string,
    value: string,
    autoFocus: bool,
    sx: shape({}),
    onBlur: func,
};

InputBox.defaultProps = {
    label: "Enter Text",
    size: "small",
    error: false,
    width: "15rem",
    height: "5rem",
    onChange: undefined,
    name: "text",
    inputErrorMsg: "",
    inputProps: {},
    type: "string",
    value: "",
    autoFocus: false,
    sx: {},
    onBlur: undefined,
};
