import React, { useState, useContext, useCallback, useEffect } from "react";
import { shape } from "prop-types";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./GoogleAddressInput.scss";
import { AddressDataContext } from "../../context-api/data-provider/GoogleAddressDataProvider";
// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform



export default function GoogleAddressInput(props) {
    const { styleAutoComplete, error } = props;
    const {
        googleAddress,
        setGoogleAddress,
        googleAddressPredictions,
        setGoogleAddressPredictionsHandler,
    } = useContext(AddressDataContext);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        return () => {
            console.log(' GoogleAddressInput will unmount')
            setGoogleAddress({})
        }
    }, [])

    const googleAutocomplete = async (inputTargetValue) => {
        return new Promise((resolve, reject) => {
            try {
                let sessionToken =
                    new window.google.maps.places.AutocompleteSessionToken();
                const Data = {
                    input: inputTargetValue,
                    sessionToken: sessionToken,
                    componentRestrictions: { country: "us" },
                };
                const autocomplete =
                    new window.google.maps.places.AutocompleteService().getPlacePredictions(
                        Data,
                        resolve
                    );
                autocomplete.then((res) => {
                    if (res.predictions && res.predictions.length) {
                        const { predictions } = res;
                        const options = predictions.map((obj) => ({
                            ...obj,
                            label: obj.description,
                        }));
                        setOptions(options);
                        setGoogleAddressPredictionsHandler({
                            key: inputTargetValue,
                            predictions,
                        });
                    }
                });
            } catch (e) {
                console.log("Error occurred while searching address:  ", e);
                reject(e);
            }
        });
    };

    const debounceData = useCallback(
        debounce((newInputValue) => {
            if (googleAddressPredictions[newInputValue]) {
                const options = googleAddressPredictions[newInputValue].predictions.map(
                    (obj) => ({
                        ...obj,
                        label: obj.description,
                    })
                );
                setOptions(options);
            } else {
                googleAutocomplete(newInputValue);
            }
        }, 1000),
        [googleAddressPredictions]
    );
    const handleOnInputChange = (event, newInputValue) => {
        if (newInputValue.length >= 2) {
            debounceData(newInputValue);
        }
    };

    const onChangeAutoComplete = (event, newValue) => {
        if (newValue !== null) {
            setOptions(newValue ? [newValue, ...options] : options);
            const [addressLine, city, state, country] =
                newValue.description.split(",");
            const addressData = { ...newValue, addressLine, city, state, country };
            setGoogleAddress(addressData);
        } else {
            setGoogleAddress({});
        }
    };

    return (
        <Autocomplete
            id="google-map-demo"
            size="small"

            sx={{ width: 420, height: "auto", ...styleAutoComplete }}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            className="custom-css-outlined-input-googleaddress"
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={googleAddress.description || ""}
            onChange={onChangeAutoComplete}
            onInputChange={handleOnInputChange}
            renderOption={(props, option, { selected }) => {
                const [addressLine, city, state, country] =
                    option.description.split(",");
                return (
                    <div
                        {...props}
                        style={{
                            display: "flex",
                            lineHeight: "15px",
                            display: "flex",
                        }}
                    >
                        <Box>
                            <LocationOnIcon
                                fontSize="medium"
                                style={{
                                    position: "relative",
                                    display: "block",
                                }}
                            />
                        </Box>
                        <span style={{ display: "flex", flexWrap: "wrap" }}>
                            {`${addressLine + "\t"},`}
                            <span style={{ fontSize: "11px", color: "gray" }}>
                                {`${city}, ${state}, ${country}`}
                            </span>
                        </span>
                    </div>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} label="Search address" fullWidth error={error} />
            )}
        />
    );
}

GoogleAddressInput.propTypes = {
    styleAutoComplete: shape(),
};
GoogleAddressInput.defaultProps = {
    styleAutoComplete: {},
};
