
import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import { API_NETWORK_ERROR, localStorageKeys } from "../../utils/constants";
import { onLoadInformationAPI } from './../api-services/Onloadinfo'


export const GlobalStateDataContext = createContext();
const GlobalStateDataProvider = ({ children }) => {
    const { REFRESH_TOKEN, TOKEN } = localStorageKeys;

    const hasTokens = !!(localStorage.getItem(REFRESH_TOKEN) && localStorage.getItem(TOKEN));
    const [isLoggedIn, setIsLoggedIn] = useState(hasTokens);
    const [showCongrat, setShowCongrat] = useState(false)
    const [zipcodeInfo, setZipcodeInfo] = useState({
        latitude: null,
        longitude: null,
        managing_branch_name: null,
        googlePlaceId: null,
        arrayOfNeighbourGooglePlaceId: [],
        arrayOfNeighbourZipcodes: [],
    })

    const [onLoadInfoResponse, setOnLoadInfoResponse] = useState({
        loading: false,
        success: null,
        message: null,
        data: null,

    })

    const OnLoadInformation = async (payload) => {
        try {
            await setOnLoadInfoResponse({
                loading: true,
                success: null,
                message: null,
                data: null,
            });

            const data = await onLoadInformationAPI(payload);


            if (data.hasError) {
                await setOnLoadInfoResponse({
                    loading: false,
                    data: null,
                    success: !data.hasError,
                    message: data.message
                });
                return
            }

            if (data.data?.success) {
                await setOnLoadInfoResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    message: data.data?.message,

                });



            } else {
                await setOnLoadInfoResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    // message: data.data?.errors[0]?.CustomMessage || data.data?.CustomMessage,
                    message: data.data?.CustomMessage || 'Backend Validation errors',
                });
            }
            return;
        } catch (error) {
            console.log('---Catch black onSignup', error)
            setOnLoadInfoResponse({ loading: false, data: null, success: false, message: error, })

        }
    };



    return (

        <GlobalStateDataContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                zipcodeInfo,
                setZipcodeInfo,
                OnLoadInformation,
                onLoadInfoResponse,
                setOnLoadInfoResponse,
                showCongrat,
                setShowCongrat

            }}
        >
            {children}
        </GlobalStateDataContext.Provider>
    )


}

export default GlobalStateDataProvider;
