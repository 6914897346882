import React, { useState, useEffect, useContext } from 'react'
import AtomicButton from '../../atomic/button/Button'
import Signup from '../signup/Signup'
import { GlobalStateDataContext } from '../../context-api/data-provider/GlobalStateDataProvider'
import zipcodeBusinessStats from '../../utils/zipcode.business.stats'
import Chip from '@mui/material/Chip';
import { verbiage } from './../../utils/constants'
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import GoogleMap from '../../composed/googlemap/GoogleMap'

const CongratsStep = () => {
    const [showSignup, setShowSignup] = useState(false)
    const { zipcodeInfo, setZipcodeInfo } = useContext(GlobalStateDataContext)
    const { managing_branch_name } = zipcodeInfo
    const { CONGRATS_MSG } = verbiage


    useEffect(() => {
        setShowSignup(false)

    }, [])

    const handleSubmit = async (e) => {
        setShowSignup(true)
    };

    if (showSignup) {
        return (<Signup />)
    } else {
        return (

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid white',
                backgroundColor: 'white',
                width: isIpad ? '525px' : '',
                height: isIpad ? '100%' : 'auto',
                borderRadius: '10px',
                margin: isIpad ? ' 15% 15%' : '05% 10% 10% 20%',
                boxShadow: '0 3px 6px 0 #000000',
                padding: isIpad ? '30px' : '40px'
            }}>
                <div style={{ marginBottom: '10px', color: '#3F3F6E', fontSize: isIpad ? '20px' : '30px', fontWeight: '700' }}> Woow Congratulations</div>
                <div style={{ marginBottom: '20px', color: '#3F3F6E', fontSize: isIpad ? '12px' : '16px', fontWeight: '400' }}>{CONGRATS_MSG}</div>
                <div style={{ marginBottom: '05px', color: '#3F3F6E', fontSize: '14px', fontWeight: '700' }}>Exculsive Visibility Zipcodes </div>
                <div style={{ marginBottom: '10px', color: '#3F3F6E', fontSize: '14px', fontWeight: '400' }}>{
                    zipcodeBusinessStats(managing_branch_name).zipcodes.map((el, index) => (

                        <Chip label={el} variant="outlined" key={index} size='small' sx={{ margin: '2px', borderColor: 'lightgrey' }} />
                    ))


                }</div>
                <div style={{ marginBottom: '05px', color: '#3F3F6E', fontSize: '14px', fontWeight: '700' }}>Population Stats</div>
                <div style={{ marginBottom: '10px', color: '#3F3F6E', fontSize: '14px', fontWeight: '400' }}>{zipcodeBusinessStats(managing_branch_name).population}</div>
                <AtomicButton
                    label="Let's signup"
                    handleSubmit={handleSubmit}
                    style={{ backgroundColor: '#3F3F6E' }}
                    loading={false}
                    disabled={false}
                />
                {isIpad && <div style={{ width: '525px', height: '585px', backgroundColor: 'blue', overflow: 'hidden' }}><GoogleMap /> </div>}
            </div>
        )
    }
}

export default CongratsStep