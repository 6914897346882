import React, { useContext, useState, useEffect } from 'react'
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import { GlobalStateDataContext } from './context-api/data-provider/GlobalStateDataProvider'
import logo from './logo.svg';
import './App.css';
import SignupPage from "./pages/signup-page/Signup-Page";
import LoginPage from "./pages/login-page/Login-Page";
import NoMatch from "./composed/nomatch/NoMatch";
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';


function App() {

  const { OnLoadInformation, onLoadInfoResponse } = useContext(GlobalStateDataContext)
  const [publicIp, setPublicIp] = useState(null)

  // console.log('-----onLoadInfoResponse', onLoadInfoResponse)

  useEffect(() => {

    publicIpv4().then((ip) => {
      setPublicIp(ip)

      const payload = {
        public_ip: ip
      }
      OnLoadInformation(payload)

    })

  }, [])

  return (
    <div>
      <Routes>
        <Route path='/' element={<LoginPage />}></Route>
        <Route path='/signup' element={<SignupPage />}></Route>
        <Route path='/login' element={<LoginPage />}></Route>
        <Route path='*' element={<NoMatch textmessage='No route found' />}></Route>



      </Routes>
    </div>
  );
}

export default App;
