const screenWidth = window.screen.availWidth;
export const isExtraSmallScreen = screenWidth <= 360;
export const isSmallScreen = screenWidth > 360 && screenWidth <= 767; //tab
export const isMediumScreen = screenWidth > 767 && screenWidth < 1124;
export const isLargeScreen = screenWidth >= 1124;

export const EXTRA_SMALL_SCREEN = "extraSmall"; // xs
export const SMALL_SCREEN = "small"; // sm
export const MEDIUM_SCREEN = "medium"; // med
export const LARGE_SCREEN = "large"; // large

const getScreenSize = () => {
    let screenSize = LARGE_SCREEN;
    if (isExtraSmallScreen) {
        screenSize = EXTRA_SMALL_SCREEN;
    } else if (isSmallScreen) {
        screenSize = SMALL_SCREEN;
    } else if (isMediumScreen) {
        screenSize = MEDIUM_SCREEN;
    } else if (isLargeScreen) {
        screenSize = LARGE_SCREEN;
    }
    return screenSize;
};
export const screenSize = getScreenSize();
export const isMobile = screenSize === SMALL_SCREEN || screenSize === EXTRA_SMALL_SCREEN;
export const isIpad = !isMobile && screenSize === MEDIUM_SCREEN
export const isDesktop = !isMobile && screenSize === LARGE_SCREEN
