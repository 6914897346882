import { API_NETWORK_ERROR } from "../../utils/constants";

const responseInterceptorFailure = (error) => {
    console.log("...responseInterceptorFailure", error);
    if (error.message === 'Network Error') {
        return { hasError: true, message: API_NETWORK_ERROR };
    } else if (!error.response) {
        return { hasError: true, message: API_NETWORK_ERROR, SpecialMessage: 'Error is undefined' };
    }


    // else if (!error.status) {
    //   { hasError: true, message: API_NETWORK_ERROR };
    // }



    const { status, data } = error.response;
    let errorResponse = {};
    if (status === 400) {
        errorResponse = { hasError: true, message: data || error.toString() };
    } else if (status === 401) {
        localStorage.clear();
        window.location.href = '/login';
        errorResponse = { hasError: true, message: "Authentication failed!", status: status };
    } else if (status === 403) {
        errorResponse = { hasError: true, message: "Authentication failed!", status: status };
    } else if (status === 300) {
        errorResponse = { hasError: true, message: "Authentication failed!", status: status };
    } else if (status === 301) {
        errorResponse = { hasError: true, message: "Authentication failed!", status: status };
    } else if (status === 500) {
        errorResponse = { hasError: true, message: "Authentication failed!", status: status };
    } else {
        errorResponse = {
            hasError: true, message: "Authentication failed!",
            ResponseInterceptor: "Check response interceptor error block",
            status: status
        };
    }
    return errorResponse;
};

export default responseInterceptorFailure;
