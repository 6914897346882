import querystring from "qs";
import { localStorageKeys } from "../../utils/constants";

const { LOGIN_INFO } = localStorageKeys;

const loginInfo = localStorage.getItem(LOGIN_INFO);

export const TIMEOUT = 2000000;
export const BASE_URL = process.env.REACT_APP_API_URL;

console.log("----process.env", process.env);
export const PARAM_SERIALIZER = (params) =>
    querystring.stringify(params, { arrayFormat: "brackets" });
export const AUTH_TOKEN_VALUE = loginInfo
    ? `Bearer ${JSON.parse(loginInfo).token}`
    : "";
