import axios from "../axios";
import { SIGNUP_API, ONLOAD_INFORMATION_API } from "../ApiUrl";



// Hedaer is added just for testing to capture header getting with this API,we can also pass header with mob header extention
export const onLoadInformationAPI = async (payload) => {
    try {
        const response = await axios.post(ONLOAD_INFORMATION_API, payload, {
            headers: {
                "syed": "syed"
            }
        });
        return response;

    } catch (error) {
        console.log("onLoadInformationAPI  catch block", error);
        return { success: false, error };
    }
};