import React from 'react'
import AtomicButton from '../../atomic/button/Button'
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper';
import { useNavigate } from "react-router-dom";


const ThankYouSignup = () => {
    const navigate = useNavigate();
    const RedirectToSignup = () => navigate("/login");


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid white',
            backgroundColor: 'white',
            width: isIpad ? '425px' : '',
            height: isIpad ? '100%' : 'auto',
            borderRadius: '10px',
            margin: isIpad ? ' 15% 20%' : '05% 10% 10% 20%',
            boxShadow: '0 3px 6px 0 #000000',
            padding: isIpad ? '10px' : '40px'
        }}>

            <div style={{ marginBottom: '20px', color: '#3F3F6E', fontSize: '30px', fontWeight: '700' }}> Thank you</div>
            <div style={{ marginBottom: '02px', color: '#3F3F6E', fontSize: '16px', fontWeight: '500' }}> Our mission is to bring more value to our customers</div>
            <div style={{ marginBottom: '20px', color: '#3F3F6E', fontSize: '16px', fontWeight: '500' }}> Please login to start setting up your account</div>
            <AtomicButton
                label="Login"
                handleSubmit={RedirectToSignup}
                style={{ backgroundColor: '#3F3F6E' }}
                loading={false}
                disabled={false}
            />


        </div>
    )
}

export default ThankYouSignup