import React, { useState } from 'react'
import Checkbox from "@mui/material/Checkbox";

function AtomicCheckbox(props) {


    const { size = 'small', sx = {}, disabled = false, onChange = null, checked = false } = props

    // const handleChange = (event) => {
    //   setChecked(event.target.checked);
    // };



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <Checkbox
            size={size}
            checked={checked}
            onChange={onChange}
            style={sx}
            disabled={disabled}
        />
    )
}

export default AtomicCheckbox