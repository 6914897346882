import React, { useContext, useState, useEffect } from 'react'
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import AtomicButton from '../../atomic/button/Button'
import InputBox from '../../atomic/inputbox/InputBox'
import SelectBox from '../../atomic/selectbox/SelectBox'
import GoogleAddressInput from '../googleaddressinput/GoogleAddressInput'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import {TimePicker from "@mui/x-date-pickers/TimePicker";
import { isValidEmail, isValidZipcodeFormat, isValidAptNo, isFullName, isMobileNo, isPassword } from "../../utils/regexValidation"
import { AddressDataContext } from '../../context-api/data-provider/GoogleAddressDataProvider'
import { SignUpDataContext } from '../../context-api/data-provider/SignupDataProvider'
import { FutureCustomerContext } from '../../context-api/data-provider/FutureCustomerDataProvider'
import TimePickerInput from '../../atomic/timepicketinput/TimePickerInput'
import moment from 'moment'
import dayjs from 'dayjs'
import { Alert, AlertTitle } from '@mui/material';
import AtomicCheckbox from '../../atomic/checkbox/Checkbox';
import responseErrorMapping from '../../utils/error.response.mapping';
import { API_NETWORK_ERROR } from './../../utils/constants';
import { Error404 } from '../error404/Error404';
import ThankYouSignup from './../thankyou-signup/ThankYouSignup'


const Signup = () => {
    const { googleAddress } = useContext(AddressDataContext);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState()

    const { signupAPIResponse,

        onSignUp,
        onServingZipcode,
        setServingZipcodeAPIResponse,
        servingZipcodeAPIResponse } = useContext(SignUpDataContext);

    const { futureCustomerAPIResponse,
        setFutureCustomerAPIResponse,
        onFutureCustomer, } = useContext(FutureCustomerContext);
    const [validationErrors, setValidationErrors] = useState(null)
    const bizOptions = ['Cloud kitchen', 'Restaurant', 'Home chef', 'Catering'];
    const cuisineOptions = ['Indian', 'Mexican', 'American chinese', 'Italian', 'Lebanese', 'Turkish', 'Fast food', 'Chinese', 'Mix of all']
    const address = (googleAddress.description || "").replaceAll(",", "")
    const [formValues, setFormValues] = useState({ bizType: 'Restaurant', cuisineType: 'Indian' })
    const { email, branchName, phoneNo, bizType, firstName, lastName, password, reTypePassword,
        operation_start_time, operation_end_time, referralNo, cuisineType } = formValues
    const [checked, setChecked] = useState(false);
    const { data, loading, message, success } = signupAPIResponse
    const [backendValidationError, setBackendValidationError] = useState(false)
    const [openOTPcomponent, setOpenOTPcomponent] = useState(false)
    const [notServingLocation, setNotServingLocation] = useState(false)


    useEffect(() => {
        setBackendValidationError(false)
        setOpenOTPcomponent(false)
        setNotServingLocation(false)
        if (success === false && message === 'Backend Validation errors') {
            setBackendValidationError(true)
        }

        if (success && message === 'Successfully created new kitchen') {
            setOpenOTPcomponent(true)
        }
        if (success === false && message === 'Sorry we do not serve in your area') {
            setNotServingLocation(true)
        }

    }, [data])

    const onChangeHandler = (key) => (value, timepickerContext) => {
        if (key === 'bizType') {
            setFormValues({ ...formValues, [key]: value });
            return
        }

        if (key === 'cuisineType') {

            setFormValues({ ...formValues, [key]: value });
            return
        }


        if (key === 'operation_start_time' || key === 'operation_end_time') {

            if (key === 'operation_start_time') {
                setStartTime(value); // unformated value neede for timepicker component 
            } else {

                setEndTime(value); // unformated value neede for timepicker component 
            }

            setFormValues({ ...formValues, [key]: value.format('hh:mm A') });
            return

        }

        value.preventDefault();
        const val = value.target.value;

        if (key === "phoneNo") {
            if (val.length <= 10) setFormValues({ ...formValues, [key]: val });
            return
        }
        setFormValues({ ...formValues, [key]: val });

    };


    const handleClick = () => {
        setChecked(!checked)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        let Errors = {}
        if (!address.length > 0) {
            Errors.address = 'Address is mandatory'
        }
        if (branchName === undefined) {
            Errors.branchName = 'Branch Name is mandatory'
        }

        if (operation_start_time === undefined) {
            Errors.operation_start_time = 'Start time is mandatory'
        }
        if (operation_end_time === undefined) {
            Errors.operation_end_time = 'End time is mandatory'
        }

        if (referralNo === undefined || isValidAptNo(referralNo)) {
            Errors.referralNo = 'Invalid Referral No format'
        }
        if (!isFullName(firstName) || firstName === undefined) {
            Errors.firstName = "Invalid First name format"
        }
        if (!isFullName(lastName) || lastName === undefined) {
            Errors.lastName = "Invalid Last name format"
        }


        if (!isValidEmail(email)) {
            Errors.email = 'Invaild email format'
        }
        if (!isMobileNo(phoneNo)) {
            Errors.phoneNo = 'Invaild Phone No format'
        }
        if (!isPassword(password)) {
            Errors.password = 'Invaild password format'

        }
        if (!isPassword(reTypePassword)) {
            Errors.reTypePassword = 'Invaild reTypePassword format'

        }

        if (Object.keys(Errors).length > 0) {
            setValidationErrors(Errors);
        } else {
            setValidationErrors(null);
            const payload = {
                email: email,
                kitchenName: branchName,
                phone_no: phoneNo,
                account_type: bizType,
                password: password,
                retype_password: reTypePassword,
                firstName: firstName,
                lastName: lastName,
                address: address,
                operation_start_time: operation_start_time,
                operation_end_time: operation_end_time,
                referral_no: referralNo,
                cuisine: cuisineType,
                contract_type: "HARD_CODED_ONUI"
            }

            await onSignUp(payload);

        }
    };

    if (success === false && message === API_NETWORK_ERROR) {
        return (<Error404 />)
    }

    if (openOTPcomponent) {
        return (<ThankYouSignup />)
    }

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid white',
                backgroundColor: 'white',
                width: isIpad ? '425px' : '',
                height: isIpad ? '100%' : 'auto',
                borderRadius: '10px',
                margin: isIpad ? ' 15% 20%' : '05% 10% 10% 20%',
                boxShadow: '0 3px 6px 0 #000000',
                padding: isIpad ? '30px' : '40px'
            }}>

                {(backendValidationError || notServingLocation) && (
                    <Alert id="alert-box" severity={isIpad ? '' : 'error'} sx={{ width: isIpad ? "90%" : "385px", border: "solid 1px #E74C3C", marginBottom: "5px", backgroundColor: '#FDEDEB', color: 'red' }}>
                        {isIpad ? <div style={{ fontSize: '12px', fontWeight: 600 }}>Error</div> : <AlertTitle sx={{ fontSize: '14px', fontWeight: 600 }}>Error</AlertTitle>}

                        {notServingLocation && <div>{responseErrorMapping(message)}</div>}
                        {backendValidationError && <ul>
                            {data?.data?.errors?.map((el, index) => (
                                <li style={{ fontSize: '12px' }} key={index}>
                                    {responseErrorMapping(el.ErrorMessage)}
                                </li>
                            ))}
                        </ul>}
                    </Alert>)
                }

                {validationErrors !== null && (
                    <Alert
                        severity={isIpad ? '' : 'error'}
                        sx={{ width: isIpad ? "90%" : "92%", border: "solid 1px #E74C3C", backgroundColor: '#FDEDEB', color: 'red' }}
                    >
                        {isIpad ? <div style={{ fontSize: '12px', fontWeight: 600 }}>Error</div> : <AlertTitle sx={{ fontSize: '14px', fontWeight: 600 }}>Error</AlertTitle>}

                        <ul>
                            {Object.keys(validationErrors).map((el, index) => (
                                <li style={{ fontSize: '12px' }} key={index}>
                                    {" "}
                                    <strong>{validationErrors[el]}</strong>
                                </li>
                            ))}
                        </ul>
                    </Alert>)}

                <div style={{ marginBottom: '20px', color: '#3F3F6E', fontSize: isIpad ? '20px' : '30px', fontWeight: '700' }}> Signup</div>
                <GoogleAddressInput
                    styleAutoComplete={{ width: isMobile ? "350px" : '98%', margin: isMobile ? "5px 0px" : "10px 5px 5px 0px" }}
                    error={validationErrors !== null && validationErrors["address"] && validationErrors["address"].length > 0}
                />

                <InputBox
                    type="text"
                    label="Email*"
                    size="small"
                    sx={{
                        width: "98%",
                        height: "auto",
                        margin: "10px 5px 5px 0px",
                    }}
                    name="email"
                    value={email}
                    maxLength="10"
                    error={validationErrors !== null && validationErrors["email"] && validationErrors["email"].length > 0}
                    onChange={onChangeHandler("email")}
                />
                <InputBox
                    type="text"
                    label="Branch Name*"
                    size="small"
                    sx={{
                        width: "98%",
                        height: "auto",
                        margin: "10px 5px 5px 0px",
                    }}
                    name="branchName"
                    value={branchName}
                    maxLength="10"
                    error={validationErrors !== null && validationErrors["branchName"] && validationErrors["branchName"].length > 0}
                    onChange={onChangeHandler("branchName")}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <InputBox
                        type="number"
                        label="Phone No*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="phoneNo"
                        value={phoneNo}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["phoneNo"] && validationErrors["phoneNo"].length > 0}
                        onChange={onChangeHandler("phoneNo")}
                    />

                    <SelectBox
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        label="Biz Type"
                        value={bizType}
                        onChange={onChangeHandler("bizType")}
                        options={bizOptions}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <InputBox
                        type="text"
                        label="First Name*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="firstName"
                        value={firstName}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["firstName"] && validationErrors["firstName"].length > 0}
                        onChange={onChangeHandler("firstName")}
                    />
                    <InputBox
                        type="text"
                        label="Last Name*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="lastName"
                        value={lastName}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["lastName"] && validationErrors["lastName"].length > 0}
                        onChange={onChangeHandler("lastName")}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <InputBox
                        type="password"
                        label="Password*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="password"
                        value={password}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["password"] && validationErrors["password"].length > 0}
                        onChange={onChangeHandler("password")}
                    />
                    <InputBox
                        type="password"
                        label="Retype Password*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="reTypePassword"
                        value={reTypePassword}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["reTypePassword"] && validationErrors["reTypePassword"].length > 0}
                        onChange={onChangeHandler("reTypePassword")}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <TimePickerInput
                        name='operation_start_time'
                        id='operation_start_time'
                        label='Start Time'
                        disabled={false}
                        disableFuture={false}
                        size='small'
                        sx={{
                            minWidth: '100px !important',
                            width: "96.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        value={startTime}
                        variant='outlined'
                        onChange={onChangeHandler("operation_start_time")} />

                    <TimePickerInput
                        name='operation_end_time'
                        label='End Time'
                        disabled={false}
                        disableFuture={false}
                        id='operation_end_time'
                        size='small'
                        sx={{
                            minWidth: '100px !important',
                            width: "96.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        value={endTime}
                        variant='outlined'
                        onChange={onChangeHandler("operation_end_time")} />

                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <SelectBox
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        label="Cuisine Type"
                        value={cuisineType}
                        onChange={onChangeHandler("cuisineType")}
                        options={cuisineOptions}
                    />
                    <InputBox
                        type="text"
                        label="Referral No*"
                        size="small"
                        sx={{
                            width: "48.5%",
                            height: "auto",
                            margin: "10px 5px 5px 0px",
                        }}
                        name="referralNo"
                        value={referralNo}
                        maxLength="10"
                        error={validationErrors !== null && validationErrors["referralNo"] && validationErrors["referralNo"].length > 0}
                        onChange={onChangeHandler("referralNo")}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <AtomicCheckbox disabled={false}
                        checked={checked}
                        onChange={handleClick}
                        sx={{ padding: isMobile ? '0' : '0px', margin: isMobile ? 0 : "10px 5px 5px 0px", color: "#3F3F6E" }} />
                    <div style={{ marginTop: '8px' }}> Terms & Condition</div>
                </div>
                <AtomicButton
                    label="Signup"
                    handleSubmit={handleSubmit}
                    style={{ backgroundColor: '#3F3F6E' }}
                    loading={loading}
                    disabled={!checked}
                />
            </div>
        </>
    )
}

export default Signup