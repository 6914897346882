import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GlobalStateDataProvider from './context-api/data-provider/GlobalStateDataProvider';
import SignUpDataProvider from './context-api/data-provider/SignupDataProvider';
import AddressDataProvider from './context-api/data-provider/GoogleAddressDataProvider';
import FutureCustomerDataProvider from './context-api/data-provider/FutureCustomerDataProvider'





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <GlobalStateDataProvider>
      <FutureCustomerDataProvider>
        <AddressDataProvider>
          <SignUpDataProvider>
            <App />
          </SignUpDataProvider>
        </AddressDataProvider>
      </FutureCustomerDataProvider>
    </GlobalStateDataProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
