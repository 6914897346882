import React from 'react';
import AtomicButton from '../../atomic/button/Button';
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import variables from "../../utils/assets/_variables.scss";
import { ERROR_404_MSG } from '../../utils/constants'
import ERROR_404 from "../../utils/assets/svg/Error404.svg";
import './Error404.scss'

export const Error404 = () => {
    const styleButton = {
        backgroundColor: variables.backgroundDarkBlue,
        width: "50%",
        marginTop: "15px",
        marginBottom: "15px",
        height: "46px"
    };
    const tryAgain = () => {
        window.location.reload()

    };
    return (
        <div className='error-404-mobile-container'>
            <div className='sorry-text'>{ERROR_404_MSG}</div>
            <img src={ERROR_404} alt="" />
            <AtomicButton
                label="Try again"
                handleSubmit={tryAgain}
                style={styleButton}
            />
        </div>
    )
}
