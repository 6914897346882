export const localStorageKeys = {
    ZIP_CODE: "zipcode",
    KITCHEN_NAMES: "kitchenNames",
    TOKEN: "token",
    REFRESH_TOKEN: "refreshToken",

};
export const API_NETWORK_ERROR = "Network Error";
export const ERROR_404_MSG = "Oops ! We're Sorry something went wrong";



export const verbiage = {
    CONGRATS_MSG: 'We have service in your Zipcode and we have few slots left in your business category',
    FUTURE_CUST_MSG: 'We dont have service or avalablity in your selected category in your area, please give us your email we will reach out to you when we have spot open in your area',
    FUTURE_CUST_SUBTITLE_MSG: 'We will update as soon as we have avalablity arises'

}





