
// Allows numbers, characters & blank spaces only
const regexFirstAndLastName = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

// Allows approx 10 numerical values
const mobileRegex = /^[0-9]{10}$/;

// Allows approx 5 numbers
const zipCodeRegex = /^[0-9]{5}$/;

// Allow only letter, Numbers, space and hyphen
const aptRegex = /[^A-Za-z0-9-]$/;

// Allow only letter and space
const fullNameRegex = /^[A-Za-z\s]*$/



// Email Address regex Ref: https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
// const emailRegex = /\S+@\S+\.\S+/;
// const emailRegex = /\S+@\S+\.\S+/;
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
    Password Regex 
    Ref: https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    Password should be 8 characters long, with atleast one uppercase letter, one lowercase letter, one number and one special character
  */
const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;




export const isValidEmail = (email) => emailRegex.test(email);
export const isValidZipcodeFormat = (zipcode) => zipCodeRegex.test(zipcode);
export const isValidAptNo = (apt) => aptRegex.test(apt);
export const isFullName = (fullname) => fullNameRegex.test(fullname);
export const isMobileNo = (mobile) => mobileRegex.test(mobile);
export const isPassword = (password) => passwordRegex.test(password);

