import React from 'react'
import { THANK_YOU_PNG } from "../../utils/imageIconsConstants";
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'


const NoServicePage = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <img alt="" src={THANK_YOU_PNG} height={40} width={40}></img>
            <div style={{ marginBottom: '02px', color: '#3F3F6E', fontSize: '20px', fontWeight: '600', justifyItems: 'center', }}>
                Page not avaialble on Mobile device </div>
            <button> Go to Desktop</button>

        </div>
    )
}

export default NoServicePage