import React, { useContext, useEffect, useState } from "react";
import { GlobalStateDataContext } from "../../context-api/data-provider/GlobalStateDataProvider";
import MapComponent from "./MapComponent";
import { isMobile, isDesktop, isIpad } from "../../utils/ScreenSizeHelper";

const GoogleMap = () => {
  const { zipcodeInfo, onLoadInfoResponse } = useContext(GlobalStateDataContext)

  // console.log(zipcodeInfo);
  return (
    <>
      {zipcodeInfo && zipcodeInfo.latitude && (
        <div style={{ display: "flex", height: "100%" }}>
          <MapComponent zipcodeInfo={zipcodeInfo} />
        </div>
      )}
    </>
  );
};

export default GoogleMap;
