import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import variables from "../../utils/assets/_variables.scss";

const defaultStyle = {
    backgroundColor: variables.backgroundDarkBlue,
    color: variables.defaultFontColor,
    marginTop: "15px",
    marginBottom: "15px",
    height: "50px"
};

const defaultLoadingButtonStyle = {
    ...defaultStyle,
    backgroundColor: variables.backgroundDisabled,
    color: variables.defaultFontColor,
}

const AtomicButton = (props) => {
    const {
        disabled = false,
        loading = false,
        handleSubmit = null,
        label = "",
        endIcon,
        startIcon,
        loadingIcon,
        loadingButtonStyle = {},
        style,
        variant = "contained"
    } = props;

    const appliedButtonStyle = { ...defaultStyle, ...style };
    const appliedLoadingButtonStyle = { ...defaultLoadingButtonStyle, ...loadingButtonStyle };

    const { backgroundColor, ...loadingStyle } = appliedButtonStyle;
    const buttonStyle =
        loading || disabled
            ? appliedLoadingButtonStyle
                ? { ...appliedLoadingButtonStyle }
                : loadingStyle
            : appliedButtonStyle;

    return (
        <Button
            disabled={disabled || loading}
            variant={variant}
            size="large "
            style={{
                width: "100%",
                height: "auto",
                textTransform: "none",
                ...buttonStyle,
            }}
            endIcon={endIcon || null}
            startIcon={
                loading
                    ? loadingIcon || <CircularProgress size={15} color="inherit" />
                    : startIcon || null
            }
            onClick={handleSubmit}
        >
            {label}
        </Button>
    );
};

export default AtomicButton;
