import React, { useContext, useState, useEffect } from 'react'
import Signup from '../../composed/signup/Signup'
import './Signup-Page.scss'
import GoogleMap from '../../composed/googlemap/GoogleMap'
import OtpVerify from '../../composed/otpverify/OtpVerify'
import CheckZipcode from '../../composed/checkzipcode/CheckZipcode'
import { isMobile, isDesktop, isIpad } from '../../utils/ScreenSizeHelper'
import NoServicePage from "../../pages/no-service-page/NoService-Page"
import { FIRST_TIME_CHECKER_PNG } from '../../utils/imageIconsConstants'
import { GlobalStateDataContext } from '../../context-api/data-provider/GlobalStateDataProvider'
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';


const SignupPage = () => {
    const { showCongrat } = useContext(GlobalStateDataContext)




    if (isMobile) {
        return (<NoServicePage />)
    }

    if (isIpad) {
        return (
            <div className='signup-ipad-container'>
                <CheckZipcode />
            </div>
        )


    }

    return (
        <div className='signup-desktop-container'>

            <div className='section-1-signup-desktop'>
                <div style={{ margin: '25px', color: 'whitesmoke' }}> Company Logo</div>

                <CheckZipcode />
            </div>
            <div className='section-2-signup-desktop'>
                {!showCongrat ? <img style={{ width: '100%' }} src={FIRST_TIME_CHECKER_PNG}></img> : <GoogleMap />}</div>

        </div>

    )
}

export default SignupPage

