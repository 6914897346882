
import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import { signupAPI, servingZipcodeAPI } from './../api-services/Signup.service'




import { API_NETWORK_ERROR } from "../../utils/constants";

export const SignUpDataContext = createContext();
const SignUpDataProvider = ({ children }) => {

    const [signupAPIResponse, setSignupAPIResponse] = useState({
        loading: false,
        success: null,
        message: null,
        data: null,
    });

    const [servingZipcodeAPIResponse, setServingZipcodeAPIResponse] = useState({
        loading: false,
        success: null,
        message: null,
        data: null,
    });




    const onSignUp = async (payload) => {
        try {
            await setSignupAPIResponse({
                loading: true,
                success: null,
                message: null,
                data: null,
            });

            const data = await signupAPI(payload);

            console.log('------data', data)

            if (data.hasError) {
                await setSignupAPIResponse({
                    loading: false,
                    data: null,
                    success: !data.hasError,
                    message: data.message
                });
                return
            }

            if (data.data?.success) {
                await setSignupAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    message: data.data?.CustomMessage,

                });



            } else {
                await setSignupAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    // message: data.data?.errors[0]?.CustomMessage || data.data?.CustomMessage,
                    message: data.data?.CustomMessage || 'Backend Validation errors',
                });
            }
            return;
        } catch (error) {
            console.log('---Catch black onSignup', error)
            setSignupAPIResponse({ loading: false, data: null, success: false, message: error, })

        }
    };


    const onServingZipcode = async (zipcode) => {
        try {
            await setServingZipcodeAPIResponse({
                loading: true,
                success: null,
                message: null,
                data: null,
            });

            const data = await servingZipcodeAPI(zipcode);

            console.log('------data 1122', data)

            if (data.hasError) {
                await setServingZipcodeAPIResponse({
                    loading: false,
                    data: null,
                    success: !data.hasError,
                    message: data.message
                });
                return
            }

            if (data?.data?.success) {
                await setServingZipcodeAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    message: data.data?.CustomMessage

                });

            } else {
                await setServingZipcodeAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    // message: data.data?.errors[0]?.CustomMessage || data.data?.CustomMessage,
                    message: (data.data?.ErrorMessage || data.data?.message) || 'Validation errors',
                });
            }
            return;
        } catch (error) {
            console.log('---Catch black onServingZipcode', error)
            setServingZipcodeAPIResponse({ loading: false, data: null, success: false, message: error, })

        }
    };


    return (

        <SignUpDataContext.Provider
            value={{
                signupAPIResponse,
                setSignupAPIResponse,
                onSignUp,
                onServingZipcode,
                setServingZipcodeAPIResponse,
                servingZipcodeAPIResponse

            }}
        >
            {children}
        </SignUpDataContext.Provider>
    )


}

export default SignUpDataProvider;
