// backend validation error messages mapping ,
// add backend error messages to the ist ENABLE_ANALYZER



const responseErrorMapping = (error) => {

    const errorMappingList = {

        // Signup API Errors
        'Email address already exist!': 'Incorrect email',
        'KitchenName  already exist!': 'Incorrect branch name',
        'KitchenName already exist in kitchen address model!': 'Add address again',
        'Phone no already exist': 'Incorrect phone no',
        'Please enter operation start time in format "12:00 AM" !': 'Start time format should be HH:MM AM',
        'Operation start time is mandatory!': 'Start time is mandatory',
        'kitchen account_type is mandatory!': 'bizType is mandatory',
        'Operation end time is mandatory!': 'End time is mandatory',
        'Please enter operation end time in format "12:00 AM" !': 'End time format should be HH:MM PM',
        'Cuisine mandatory!': 'Cuisine mandatory',
        'Password confirmation does not match password': 'Password and reType Password does not match',
        'Sorry we do not serve in your area': 'Sorry, our services are currently unavailable at this location. We hope to serve you in future',


        //OTP Verify Token REACT_APP_API_URL


        // Get serving zipcode  onServingZipcode

        'No Record Found in Database by that 95050': 'We dont have service or avalablity in your selected category in your area, please give us your email we will reach out to you when we have spot open in your area'



    }

    return errorMappingList[error] || error

}

export default responseErrorMapping
