import axios from "../axios";
import { SIGNUP_API, FUTURE_CUSTOMER_API, GET_SERVING_ZIPCODE_ADMIN_API } from "../ApiUrl";

export const signupAPI = async (payload) => {

    try {
        const response = await axios.post(SIGNUP_API, payload);
        return response;

    } catch (error) {
        console.log("signupAPI catch block", error);
        return { success: false, error };
    }
};

export const futureCustomerAPI = async (payload) => {

    try {
        const response = await axios.post(FUTURE_CUSTOMER_API, payload);
        return response;

    } catch (error) {
        console.log("futureCustomerAPI catch block", error);
        return { success: false, error };
    }
};

export const servingZipcodeAPI = async (zipcode) => {

    try {
        const response = await axios.get(GET_SERVING_ZIPCODE_ADMIN_API, {
            params: {
                zipcode,
            },
        });
        return response;

    } catch (error) {
        console.log("servingZipcodeAPI catch block", error);
        return { success: false, error };
    }
};