import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./SelectBox.scss";

export default function SelectBox(props) {
    const { sx, label, options = [], onChange, value = "", error = false } = props;
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        if (onChange) {
            onChange(event.target.value);
        } else {
            setAge(event.target.value);
        }
    };

    return (
        <div style={sx} id="selector">
            <FormControl fullWidth error={error}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select

                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    onChange={handleChange}
                    className="demo-simple-select"
                    // sx={sx}
                    size="small"
                >
                    {options.map((el, i) => (
                        <MenuItem key={i} value={el}>
                            {el}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
