import React, { createContext, useState } from "react";
import { node } from "prop-types";
import axios from "axios";


export const AddressDataContext = createContext();

const AddressDataProvider = ({ children }) => {

    // Google Address Selected State
    const [googleAddress, setGoogleAddress] = useState({});

    /**
   * stores the google address predictions array for each key search
   * if the data for the key is found in this state, then the data or predictions are served from here
   * else a google api for fetching the predictions will happen and the res.predictions with the keys are stored
   */
    const [googleAddressPredictions, setGoogleAddressPredictions] = useState({});
    const setGoogleAddressPredictionsHandler = ({ key, predictions }) => {
        const data = {
            ...googleAddressPredictions,
            [key]: { created: new Date(), predictions },
        };
        // console.log("SETING ....", key, predictions, data);
        setGoogleAddressPredictions(data);
    };



    return (
        <AddressDataContext.Provider
            value={{
                googleAddress,
                setGoogleAddress,
                googleAddressPredictions,
                setGoogleAddressPredictions,
                setGoogleAddressPredictionsHandler

            }}
        >
            {children}
        </AddressDataContext.Provider>
    );
};

AddressDataProvider.propTypes = {
    children: node.isRequired,
};

export default AddressDataProvider;
