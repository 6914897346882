import axios from "axios";
import requestInterceptor from "./requestInterceptor";
import responseInterceptorSuccess from "./responseInterceptorSuccess";
import responseInterceptorFailure from "./responseInterceptorFailure";
import {
    TIMEOUT,
    BASE_URL,
    PARAM_SERIALIZER,
    AUTH_TOKEN_VALUE,
} from "./defaultConstants";

// setting axios initial default config
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = BASE_URL;
axios.defaults.paramsSerializer = PARAM_SERIALIZER;
if (AUTH_TOKEN_VALUE) {
    axios.defaults.headers.common.Authorization = AUTH_TOKEN_VALUE;
}

// request interceptor
axios.interceptors.request.use(requestInterceptor);

// response interceptor
axios.interceptors.response.use(
    responseInterceptorSuccess,
    responseInterceptorFailure
);

export default axios;
