
import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import { futureCustomerAPI } from './../api-services/Signup.service'




import { API_NETWORK_ERROR } from "../../utils/constants";

export const FutureCustomerContext = createContext();
const FutureCustomerDataProvider = ({ children }) => {

    const [futureCustomerAPIResponse, setFutureCustomerAPIResponse] = useState({
        loading: false,
        success: null,
        message: null,
        data: null,
    });

    const onFutureCustomer = async (payload) => {
        try {
            await setFutureCustomerAPIResponse({
                loading: true,
                success: null,
                message: null,
                data: null,
            });

            const data = await futureCustomerAPI(payload);

            if (data.hasError) {
                await setFutureCustomerAPIResponse({
                    loading: false,
                    data: null,
                    success: !data.hasError,
                    message: data.message
                });
                return
            }

            if (data.data?.success) {
                await setFutureCustomerAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    message: data.data?.message,

                });


            } else {
                await setFutureCustomerAPIResponse({
                    loading: false,
                    data: data,
                    success: data.data?.success,
                    message: data.data?.message,
                });
            }
            return;
        } catch (error) {
            console.log('---Catch black onFutureCustomer', error)
            setFutureCustomerAPIResponse({ loading: false, data: null, success: false, message: error, })

        }
    };



    return (

        <FutureCustomerContext.Provider
            value={{
                futureCustomerAPIResponse,
                setFutureCustomerAPIResponse,
                onFutureCustomer,
            }}
        >
            {children}
        </FutureCustomerContext.Provider>
    )


}

export default FutureCustomerDataProvider;
